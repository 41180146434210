<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'service-admin' }">Service Admin</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">TinyDNS Configuration</h1>
                <!-- <p class="text-caption text-center">{{ name }}</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <v-textarea :value="tinydnsconfig"></v-textarea>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        tinydnsconfig: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
    },
    watch: {
    },
    methods: {
        init() {
            this.loadTinydnsConfig();
        },
        async loadTinydnsConfig() {
            try {
                this.$store.commit('loading', { loadTinydnsConfig: true });
                const response = await this.$client.main().service.getTinydnsConfig();
                if (response?.text) {
                    this.tinydnsconfig = response.text;
                }
            } catch (err) {
                console.error('loadTinydnsConfig failed', err);
            } finally {
                this.$store.commit('loading', { loadTinydnsConfig: false });
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
